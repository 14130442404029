import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Container, Loader } from "rsuite";
import Sidebar from './Sidebar'
import TopHeader from "./TopHeader";
import { getCurrentUser, isLogin, setLogin, setUser } from "../../redux/authSlice";
import { useCurrentUserMutation } from "../../redux/api";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useTranslation } from "react-i18next";

const SecurityLayout = ({ children }) => {
    return (
        <div className="show-fake-browser sidebar-page">
            <Container>
                <Sidebar />
                <Container>
                    <TopHeader />
                    {children}
                </Container>
            </Container>
        </div>
    )
}

const SecurityLayoutRoute = () => {
    let location = useLocation();
    const { t } = useTranslation();
    const [getUser, { isLoading }] = useCurrentUserMutation()
    const dispatch = useAppDispatch()
    useEffect(() => {
        getUser().unwrap().then(response => {
            dispatch(setUser(response))
        }).catch(err => {
            dispatch(setUser(null))
            dispatch(setLogin({ auth: false }))
        })
    }, [])

    const isAuth = useAppSelector(isLogin)
    const currentUser = useAppSelector(getCurrentUser)
    if (isLoading)
        return <Loader size="lg" backdrop content={`${t("general.loading")}...`} vertical />
    else if (!(isAuth || currentUser)) {
        return <Navigate to="/user/login" state={{ from: location }} replace />;
    }
    else {
        return <SecurityLayout>
            <Outlet />
        </SecurityLayout>
    }
};

export default SecurityLayoutRoute