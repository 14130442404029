import { useMemo } from "react";
import { useAppSelector } from ".";
import { getCurrentUser } from "../redux/authSlice";

const useUser = () => {
  const currentUser = useAppSelector(getCurrentUser);
  return [currentUser];
  // return useMemo(() => ({ companies, isLoading }), [companies, isLoading])
};

export default useUser;
