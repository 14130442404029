import SecurityLayoutRoute from "./SecurityLayout";
import UserLayoutRoute from "./UserLayout";
import Login from "../../pages/user/login";
import NotFound from "./NotFound";
import Home from "../../pages/home";
import MyEducations from "../../pages/myEducations";
import MyLessons from "../../pages/myLessons";
import MyVideos from "../../pages/myVideos";
import MyTasks from "../../pages/myTasks";
import MyDocuments from "../../pages/myDocuments";
import ChangePassword from "../../pages/user/changePassword";
import MyInstallment from "../../pages/myInstallment";

const routes = [
  {
    path: "/user",
    name: "user",
    component: <UserLayoutRoute />,
    routes: [
      {
        name: "login",
        path: "/user/login",
        component: <Login />,
      },
      {
        name: "forgotpasword",
        path: "/user/forgot-password",
      },
    ],
  },
  {
    path: "/",
    name: "security",
    component: <SecurityLayoutRoute />,
    routes: [
      {
        name: "dashboard",
        icon: "home",
        path: "/",
        component: <Home />,
      },
      {
        name: "changePassword",
        icon: "home",
        path: "/change-password",
        component: <ChangePassword />,
      },
      {
        name: "educations",
        icon: "user",
        path: "/educations",
        component: <MyEducations />,
      },
      {
        name: "lessons",
        icon: "user",
        path: "/lessons",
        component: <MyLessons />,
      },
      {
        name: "tasks",
        icon: "user",
        path: "/tasks",
        component: <MyTasks />,
      },
      {
        name: "videos",
        icon: "user",
        path: "/videos",
        component: <MyVideos />,
      },
      {
        name: "documents",
        icon: "user",
        path: "/documents",
        component: <MyDocuments />,
      },
      {
        name: "intallement",
        icon: "user",
        path: "/installment",
        component: <MyInstallment />,
      },
      {
        path: "*",
        name: "notfound",
        component: <NotFound />,
      },
    ],
  },
  {
    path: "*",
    name: "notfound",
    component: <NotFound />,
  },
];

export default routes;
