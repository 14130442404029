import { useTranslation } from "react-i18next";
import { Col, FlexboxGrid, Grid, List, Loader, Panel, Row } from "rsuite";
import useUser from "../../../hooks/useUser";
import { useGetAdvisorByStudentIdQuery } from "../../../redux/studentApi";

const Advisor = () => {
  const [currentUser] = useUser();
  const { t } = useTranslation();
  const { data, isLoading } = useGetAdvisorByStudentIdQuery(
    {
      id: currentUser?.studentId,
    },
    { skip: !currentUser?.studentId }
  );
  if (isLoading) {
    return (
      <Loader
        vertical
        backdrop
        center
        size="lg"
        content={`${t("general.loading")}...`}
      />
    );
  }
  return (
    <Panel
      header="Danışman Bilgileri"
      bordered
      shaded
      bodyFill
      style={{ padding: 20 }}
    >
      <List hover>
        {data && data?.data.length
          ? data?.data.map((m, index) => (
              <List.Item
                key={`${m.studentEducation_teacher.name} ${m.studentEducation_teacher.surname}`}
                index={index + 1}
              >
                <FlexboxGrid>
                  <FlexboxGrid.Item
                    colspan={10}
                    style={{
                      paddingLeft: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "60px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        paddingBottom: 5,
                        whiteSpace: "nowrap",
                        fontWeight: 500,
                      }}
                    >{`${m.studentEducation_teacher.name} ${m.studentEducation_teacher.surname}`}</div>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    colspan={8}
                    style={{
                      paddingLeft: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "60px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflow: "hidden",
                    }}
                  >
                    {`${m.studentEducation_teacher.email}`}
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    colspan={6}
                    style={{
                      paddingLeft: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "60px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflow: "hidden",
                    }}
                  >
                    {`${m.studentEducation_teacher.phone}`}
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </List.Item>
            ))
          : null}
      </List>
    </Panel>
  );
};

export default Advisor;
