import { Edit, FileDownload, PlayOutline } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  Grid,
  IconButton,
  Modal,
  Row,
  Schema,
} from "rsuite";
import DataTable from "../../components/dataTable";
import Page from "../../components/layout/Page";
import { getEnumName, initParams } from "../../utils";
import { LessonVideoTypesEnum } from "../../utils/enums";
import {
  useGetLessonVideosQuery,
  useGetStudentLessonVideosQuery,
} from "../../redux/lessonVideoApi";
import TextInput from "../../components/TextInput";
import RefDropdown from "../../components/RefDropdown";
const { NumberType } = Schema.Types;

const MyVideos = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const [params, setParams] = useState(initParams);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [open, setOpen] = React.useState(false);
  const initialValues = {
    educationId: null,
    lessonId: null,
  };
  const [formValues, setFormValue] = React.useState(initialValues);
  const { data, isLoading, refetch } = useGetLessonVideosQuery(
    { ...params, educationId: formValues.educationId },
    {
      refetchOnMountOrArgChange: true,
      // skip: !formValues.educationId,
    }
  );

  const schema = Schema.Model({
    educationId: NumberType().isRequired(
      t("pages.student.studentGrade.valid.studentEducationId_required")
    ),
  });

  const edit = (data) => {
    setSelectedVideo(data);
    handleOpen();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedVideo(null);
    setOpen(false);
  };

  const columns = [
    {
      title: t("pages.lessonVideo.educationId"),
      name: "educationId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {rowData.lessonVideo_education
            ? rowData.lessonVideo_education?.code +
              " - " +
              rowData.lessonVideo_education?.name
            : ""}
        </span>
      ),
    },
    // {
    //   title: t("pages.lessonVideo.lessonId"),
    //   name: "lessonId",
    //   sortable: true,
    //   align: "left",
    //   type: "ref",
    //   flexGrow: 1,
    //   renderCell: (rowData) => (
    //     <span>
    //       {rowData.lessonVideo_lesson
    //         ? rowData.lessonVideo_lesson?.code +
    //           " - " +
    //           rowData.lessonVideo_lesson?.name
    //         : ""}
    //     </span>
    //   ),
    // },
    {
      title: t("pages.lessonVideo.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.lessonVideo.description"),
      name: "description",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    // {
    //   title: t("pages.lessonVideo.videoTypeId"),
    //   name: "description",
    //   sortable: true,
    //   align: "left",
    //   type: "text",
    //   flexGrow: 1,
    //   renderCell: (rowData) => (
    //     <>
    //       {t(
    //         `general.enums.videoType.${getEnumName(
    //           LessonVideoTypesEnum,
    //           rowData.videoTypeId
    //         )}`
    //       )}
    //     </>
    //   ),
    // },
    // {
    //   title: t("pages.lessonVideo.isAccessAll"),
    //   name: "isAccessAll",
    //   sortable: true,
    //   align: "left",
    //   type: "boolean",
    //   flexGrow: 1,
    //   renderCell: (rowData) => (
    //     <span>{rowData.isAccessAll ? t("general.yes") : t("general.no")}</span>
    //   ),
    // },
    {
      title: t("general.table.actions"),
      name: "actions",
      width: 100,
      renderCell: (rowData) => (
        <>
          <IconButton
            onClick={() => edit(rowData)}
            size="xs"
            icon={<PlayOutline color="orange" />}
          />
        </>
      ),
    },
  ];
  return (
    <Page title={t("pages.lessonVideo.title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={setFormValue}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="educationId"
                endpoint="education/list"
                labelKey="name"
                label={t("pages.student.studentGrade.studentEducationId")}
                accepter={RefDropdown}
              />
            </Col>
            {/* <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="lessonId"
                endpoint="education/student/list"
                labelKey="name"
                label={t("pages.student.studentGrade.lessonId")}
                accepter={RefDropdown}
                query={{ studentEducationId: formValues.studentEducationId }}
              />
            </Col> */}
          </Row>
        </Grid>
        <DataTable
          size="compact"
          columns={columns}
          rowKey="id"
          wordWrap="break-word"
          loading={isLoading}
          data={data?.data}
          params={params}
          rowCount={data?.total}
          setPagination={setParams}
          refetch={refetch}
        />
      </Form>
      <Modal size="full" backdrop="static" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{selectedVideo?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedVideo?.videoTypeId === LessonVideoTypesEnum.vimeoEmbed ? (
            <div
              style={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{ __html: selectedVideo?.resource }}
            />
          ) : (
            <video src={selectedVideo?.resource} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            {t("general.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Page>
  );
};

export default MyVideos;
