import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputPicker, Loader } from "rsuite";
import { useGetCountryListQuery } from "../../redux/api";
import { initParams } from "../../utils";

const SearchCountry = ({ onChange, value, disabled = false, ...rest }) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(null);
  const { data, isLoading } = useGetCountryListQuery(
    { ...initParams, name: key },
    { refetchOnMountOrArgChange: true }
  );

  const onSearch = (key) => {
    if (key.length > 1) setKey(key);
  };

  return (
    <InputPicker
      {...rest}
      disabled={disabled}
      block
      data={data?.data}
      labelKey="name"
      valueKey="id"
      onSearch={onSearch}
      onChange={onChange}
      value={value}
      placeholder={t("pages.utils.searchCountry")}
      renderMenu={(menu) => {
        if (isLoading) {
          return (
            <div style={{ padding: 10, color: "#999", textAlign: "center" }}>
              <Loader /> {t("general.loading")}
            </div>
          );
        }
        return menu;
      }}
    />
  );
};

export default SearchCountry;
