import { api } from "./api";
const apiUrl = "https://admin.bcuni.eu/api";
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addLessonTask: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/lesson-task/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    updateLessonTask: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/lesson-task/update`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getLessonTasks: builder.query({
      query: (data) => ({
        url: `${apiUrl}/lesson-task/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getStudentLessonTasks: builder.query({
      query: (data) => ({
        url: `${apiUrl}/lesson-task/student/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getLessonTasksById: builder.query({
      query: (data) => ({
        url: `${apiUrl}/lesson-task/by-id`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    uploadStudentWork: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/lesson-task/student/upload`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddLessonTaskMutation,
  useGetLessonTasksQuery,
  useUpdateLessonTaskMutation,
  useGetLessonTasksByIdQuery,
  useGetStudentLessonTasksQuery,
  useUploadStudentWorkMutation,
} = extendedApi;
