import { Edit } from "@rsuite/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Divider,
  Form,
  Grid,
  List,
  Loader,
  Row,
  Stack,
  Table,
  useToaster,
} from "rsuite";
import { useGetInstallmentListByStudentEducationIdQuery } from "../../redux/studentApi";
import { initParams } from "../../utils";
import moment from "moment";
import { paymentTypeEnum } from "../../utils/enums";
import Page from "../../components/layout/Page";
import TextInput from "../../components/TextInput";
import RefDropdown from "../../components/RefDropdown";
import useUser from "../../hooks/useUser";
const { Column, HeaderCell, Cell } = Table;

const MyInstallment = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const [params, setParams] = React.useState(initParams);
  const currentUser = useUser();
  const initialValues = {
    studentId: null,
    studentEducationId: null,
  };
  const [formValues, setFormValue] = React.useState(initialValues);

  const { data, isLoading, refetch } =
    useGetInstallmentListByStudentEducationIdQuery(
      { ...params, studentEducationId: formValues.studentEducationId },
      {
        refetchOnMountOrArgChange: true,
        skip: !formValues.studentEducationId,
      }
    );
  const getPaidTotal = (data) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      const e = data[i];
      if (e.isPaid) sum += Number(e.price);
    }
    return sum.toFixed(2);
  };

  return (
    <Page title={t("pages.student.studentInstallment.title")}>
      <Form fluid formValue={formValues} onChange={setFormValue} ref={formRef}>
        <Grid fluid>
          <Row gutter={16}>
            {/* <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="studentId"
                label={t("pages.student.studentGrade.studentId")}
                accepter={SearchStudent}
              />
            </Col> */}
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="studentEducationId"
                endpoint="education/student/list"
                labelKey="educationName"
                label={t("pages.student.studentGrade.studentEducationId")}
                accepter={RefDropdown}
                query={{ studentId: currentUser.studentId }}
              />
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row gutter={16}>
            {isLoading ? (
              <Loader
                size="lg"
                backdrop
                content={`${t("general.loading")}...`}
                vertical
              />
            ) : (
              formValues.studentEducationId &&
              data?.data?.map((values, vIndex) => (
                <List bordered key={vIndex}>
                  <List.Item style={{ margin: 10 }}>
                    <Stack
                      divider={<Divider vertical />}
                      justifyContent="space-between"
                    >
                      <Button>
                        {t("pages.student.studentEducation.paymentTypeId")} :{" "}
                        {values.paymentTypeId === paymentTypeEnum.installment
                          ? "Taksit"
                          : "Peşin"}
                      </Button>
                      <Button>
                        {t("pages.student.studentEducation.totalPrice")} :{" "}
                        {values.price}
                      </Button>
                      <Button>
                        {t("pages.student.studentEducation.paidPrice")} :{" "}
                        {getPaidTotal(
                          values.studentPayment_studentPaymentDetail || []
                        )}
                      </Button>
                      <Button>
                        {t("pages.student.studentEducation.remainPrice")} :{" "}
                        {(
                          Number(values.price) -
                          getPaidTotal(
                            values.studentPayment_studentPaymentDetail || []
                          )
                        ).toFixed(2)}
                      </Button>
                      {/* <Button>
                        {t("pages.student.studentEducation.centerRate")} :{" "}
                        {Number(values.agencyRate) !== 100
                          ? 100 - Number(values.agencyRate) + "%"
                          : "100%"}
                      </Button>
                      <Button>
                        {t("pages.student.studentEducation.agencyRate")} :{" "}
                        {Number(values.agencyRate) !== 100
                          ? values.agencyRate + "%"
                          : "0%"}
                      </Button> */}
                    </Stack>
                  </List.Item>
                  <List.Item style={{ margin: 10 }}>
                    <Table
                      autoHeight
                      data={[
                        ...values.studentPayment_studentPaymentDetail,
                      ].sort(
                        (a, b) =>
                          Number(a.installmentNo) - Number(b.installmentNo)
                      )}
                      // onRowClick={rowData => {
                      //     console.log(rowData);
                      // }}
                      bordered
                      cellBordered
                    >
                      <Column width={100} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.installmentNo")}
                        </HeaderCell>
                        <Cell dataKey="installmentNo" />
                      </Column>
                      <Column flexGrow={1} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.paymentTypeId")}
                        </HeaderCell>
                        <Cell>
                          {(rowData) =>
                            rowData.installmentNo === 0 ? "Peşinat" : "Taksit"
                          }
                        </Cell>
                      </Column>
                      <Column flexGrow={1} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.price")}
                        </HeaderCell>
                        <Cell dataKey="price" />
                      </Column>
                      <Column flexGrow={1} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.isPaid")}
                        </HeaderCell>
                        <Cell>
                          {(rowData) => (rowData.isPaid ? "Evet" : "Hayır")}
                        </Cell>
                      </Column>
                      <Column flexGrow={1} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.expiry")}
                        </HeaderCell>
                        <Cell>
                          {(rowData) =>
                            rowData.dueDate
                              ? moment(rowData.dueDate).format("DD/MM/YYYY")
                              : "-"
                          }
                        </Cell>
                      </Column>
                      <Column flexGrow={1} align="center" fixed>
                        <HeaderCell>
                          {t("pages.student.studentEducation.paidDate")}
                        </HeaderCell>
                        <Cell>
                          {(rowData) =>
                            rowData.paymentDate
                              ? moment(rowData.paymentDate).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "-"
                          }
                        </Cell>
                      </Column>
                    </Table>
                  </List.Item>
                </List>
              ))
            )}
          </Row>
        </Grid>
      </Form>
    </Page>
  );
};

export default MyInstallment;
