export const initParams = { pagination: { page: 1, pageSize: 10 }, sorter: { field: null, type: "asc" } }

export const defaultToolbar = { filter: true, columns: true, density: true }

export const productTypeEnum = { fabric: 1, pYarn: 2 }

export const orderTypeEnum = { fason: 1, uretim: 2 }

export const unitTypeEnum = { currency: 1, amount: 2, width: 3, grams: 4, length: 5, yarnFold: 6 }

export function getKey() {
    return (Math.random() * 1e18).toString(36).slice(0, 5).toUpperCase() + '';
}

export function getEnumName(data, val) {
    for (var propName in data) {
        if (data[propName] === val) {
            return propName
        }
    }
    return val
}