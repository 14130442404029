import { Edit, FileUpload } from "@rsuite/icons";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  Grid,
  IconButton,
  Modal,
  Notification,
  Row,
  Schema,
  Uploader,
  useToaster,
} from "rsuite";
import DataTable from "../../components/dataTable";
import Page from "../../components/layout/Page";
import RefDropdown from "../../components/RefDropdown";
import TextInput from "../../components/TextInput";
import {
  useGetStudentLessonTasksQuery,
  useUploadStudentWorkMutation,
} from "../../redux/lessonTaskApi";
import { getEnumName, initParams } from "../../utils";
import { StudentTaskStatusEnum } from "../../utils/enums";
const { NumberType } = Schema.Types;

const MyTasks = () => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const toaster = useToaster();
  const [upload, { isLoading: uploading }] = useUploadStudentWorkMutation();
  const [params, setParams] = useState(initParams);
  const initialValues = {
    studentEducationId: null,
  };
  const [selectedTask, setSelectedTask] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValue] = React.useState(initialValues);
  const { data, isLoading, refetch } = useGetStudentLessonTasksQuery(
    { ...params, studentEducationId: formValues.studentEducationId },
    {
      refetchOnMountOrArgChange: true,
      skip: !formValues.studentEducationId,
    }
  );

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onerror = reject;
      fileReader.onload = function () {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const handleFileInputChange = (e) => {
    if (e.length > 0) {
      let file = e[0];
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(e[0].name)[1];
      getBase64(file.blobFile)
        .then((result) => {
          setSelectedTask({ ...selectedTask, file: result, ext: ext });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const edit = (data) => {
    setSelectedTask(data);
    handleOpen();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedTask(null);
    setOpen(false);
  };

  const approveTask = () => {
    if (selectedTask && selectedTask.file) {
      upload(selectedTask)
        .unwrap()
        .then((res) => {
          handleClose();
          setSelectedTask(null);
          refetch();
          toaster.push(
            <Notification
              closable
              type="success"
              header={t("general.error.success")}
              duration={2000}
            >
              <p>{t("general.error.success_add")}</p>
            </Notification>,
            { placement: "topEnd" }
          );
        })
        .catch((err) => {
          toaster.push(
            <Notification
              closable
              type="error"
              header={t("general.error.error")}
              duration={2000}
            >
              <p>{err?.data?.description}</p>
            </Notification>,
            { placement: "topEnd" }
          );
        });
    } else {
      toaster.push(
        <Notification
          closable
          type="error"
          header={t("general.error.error")}
          duration={2000}
        >
          <p>Bir dosya seçiniz</p>
        </Notification>,
        { placement: "topEnd" }
      );
    }
  };

  const schema = Schema.Model({
    studentEducationId: NumberType().isRequired(
      t("pages.student.studentGrade.valid.studentEducationId_required")
    ),
  });

  const columns = [
    {
      title: t("pages.lessonTask.name"),
      name: "termId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>{rowData.studentTask_lessonTask?.name}</span>
      ),
    },
    {
      title: t("pages.lessonTask.description"),
      name: "description",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>{rowData.studentTask_lessonTask?.description}</span>
      ),
    },
    {
      title: t("pages.lessonTask.statusId"),
      name: "statusId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {t(
            `general.enums.studentTask.${getEnumName(
              StudentTaskStatusEnum,
              rowData?.statusId
            )}`
          )}
        </span>
      ),
    },
    {
      title: t("pages.student.studentTask.startDate"),
      name: "startDate",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {moment(rowData.studentTask_lessonTask?.startDate).format(
            "DD/MM/YYYY"
          )}
        </span>
      ),
    },
    {
      title: t("pages.student.studentTask.sendDate"),
      name: "sendDate",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {rowData.studentTask_lessonTask?.sendDate &&
            moment(rowData.studentTask_lessonTask?.sendDate).format(
              "DD/MM/YYYY"
            )}
        </span>
      ),
    },
    {
      title: t("general.table.actions"),
      name: "actions",
      width: 100,
      renderCell: (rowData) => (
        <>
          {!(
            rowData?.statusId === StudentTaskStatusEnum.approved ||
            rowData?.statusId === StudentTaskStatusEnum.graduated
          ) && (
            <IconButton
              onClick={() => edit(rowData)}
              size="xs"
              icon={<FileUpload color="orange" />}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Page title={t("pages.lessons.title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={setFormValue}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="studentEducationId"
                endpoint="education/student/list"
                labelKey="educationName"
                formValues={formValues}
                setFormValue={setFormValue}
                label={t("pages.student.studentGrade.studentEducationId")}
                accepter={RefDropdown}
              />
            </Col>
          </Row>
        </Grid>
        <DataTable
          size="compact"
          wordWrap="break-word"
          columns={columns}
          rowKey="id"
          loading={isLoading}
          data={data?.data}
          params={params}
          rowCount={data?.total}
          setPagination={setParams}
          refetch={refetch}
        />
      </Form>
      <Modal size="sm" backdrop="static" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {selectedTask?.studentTask_lessonTask?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Uploader
            onChange={handleFileInputChange}
            draggable
            disabled={selectedTask && selectedTask.file}
            autoUpload={false}
            onRemove={() => setSelectedTask({ ...selectedTask, file: null })}
          >
            <div
              style={{
                height: 200,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>Dosya Yüklemek için sürükleyin veya tıklayın</span>
            </div>
          </Uploader>
        </Modal.Body>
        <Modal.Footer>
          <Button
            loading={uploading}
            onClick={approveTask}
            appearance="primary"
          >
            {t("general.send")}
          </Button>
          <Button
            disabled={uploading}
            onClick={handleClose}
            appearance="subtle"
          >
            {t("general.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Page>
  );
};

export default MyTasks;
