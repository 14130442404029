import React from "react";
import { Button, ButtonToolbar, Col, Container, Content, FlexboxGrid, Form, Panel, Schema } from "rsuite"
import { useTranslation } from 'react-i18next';
import { useLoginMutation } from '../../../redux/api'
import { useAppDispatch } from '../../../hooks'
import { useNavigate } from "react-router-dom";
import sha256 from 'crypto-js/sha256';
import { setLogin } from "../../../redux/authSlice";
import PasswordInput from "../../../components/PasswordInput";
import TextInput from "../../../components/TextInput";
const { StringType } = Schema.Types

const Login = () => {
    const { t } = useTranslation();
    const formRef = React.useRef();
    const [login, { isLoading }] = useLoginMutation()
    const history = useNavigate()
    const dispatch = useAppDispatch()

    const model = Schema.Model({
        userName: StringType().isRequired(t('pages.user.login.valid.usernameRequired')),
        password: StringType().isRequired(t('pages.user.login.valid.passwordRequired'))
    })

    const [formValue, setFormValue] = React.useState({
        userName: '',
        password: '',
    })

    const signIn = () => {
        if (!formRef.current.check()) {
            return;
        }
        let hashedpassword = sha256(formValue.password).toString();
        login({ ...formValue, password: hashedpassword }).unwrap().then(response => {
            dispatch(setLogin(response))
            history("/")
        }).catch(err => {
        })

    }

    return (
        <div className="show-fake-browser login-page">
            <Container>
                <Content>
                    <FlexboxGrid justify="center" style={{ marginBottom: 30 }}>
                        <h2>Brussels Capital University</h2>
                    </FlexboxGrid>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item className="animated" as={Col} colspan={24} md={6}>
                            <Panel className="box" header={<h3>{t('pages.user.login.title')}</h3>} bordered shaded>
                                <Form className="" fluid
                                    model={model}
                                    formValue={formValue}
                                    onChange={setFormValue}
                                    ref={formRef}  >
                                    <div style={{ borderRadius: 8, inset: 2, zIndex: 522 }}>
                                        <Form.Group>
                                            <TextInput
                                                name="userName"
                                                label={t('pages.user.login.username')}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <PasswordInput
                                                name="password"
                                                label={t('pages.user.login.password')}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <ButtonToolbar>
                                                <Button onClick={signIn} type="submit" loading={isLoading} appearance="primary">{t('pages.user.login.title')}</Button>
                                                {/* <Button disabled={isLoading} appearance="link">Forgot password?</Button> */}
                                            </ButtonToolbar>
                                        </Form.Group>
                                    </div>
                                </Form>
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </Container>
        </div>
    )
}

export default Login