export const GenderEnum = {
  Erkek: 1,
  Kadın: 2,
};
export const LessonTypesEnum = {
  Required: 1,
  Optional: 2,
};
export const LessonResourceTypesEnum = {
  Kitap: 1,
  EKitap: 2,
  Url: 3,
};

export const LessonVideoTypesEnum = {
  vimeoEmbed: 1,
  url: 2,
  upload: 3,
};

export const QuestionTypeEnum = {
  test: 1,
  classic: 2,
};

export const teacherTypeEnum = {
  advisor: 1,
  teacher: 2,
  dr: 3,
  doc: 4,
  prof: 5,
  lecturer: 6,
};

export const paymentTypeEnum = {
  advance: 1,
  installment: 2,
};

export const AgencyTypeEnum = {
  indivual: 1,
  corporate: 2,
};

export const DiscountTypeEnum = {
  amount: 1,
  percent: 2,
};

export const StudentTaskStatusEnum = {
  waitingForUpload: 1,
  submittedForGrading: 2,
  updated: 3,
  approved: 4,
  graduated: 5,
};

export const StudentTypeEnum = {
  new: 1,
  exists: 2,
};

export const EducationStatusEnum = {
  student: 1,
  graduationCase: 2,
  graduated: 3,
};
