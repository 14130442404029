import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Translation } from "react-i18next";
import { Notification, toaster } from "rsuite";
import { setLogin, setUser } from "./authSlice";

const apiUrl = "https://admin.bcuni.eu/api";

const responseHandler = async (args, api, extraOptions) => {
  const result = await rawBaseQuery(args, api, extraOptions);
  if (result.meta?.response?.status === 400) {
    //validation error
    toaster.push(
      <Notification
        closable
        type="error"
        header={
          <Translation>
            {(t) => <p>{t("general.error.validation_error")}</p>}
          </Translation>
        }
        duration={2000}
      >
        <p>{result?.error?.data?.message}</p>
      </Notification>,
      { placement: "topEnd" }
    );
  } else if (result.meta?.response?.status === 403) {
    //validation error
    toaster.push(
      <Notification
        closable
        type="error"
        header={
          <Translation>
            {(t) => <p>{t("general.error.not_found")}</p>}
          </Translation>
        }
        duration={2000}
      >
        <p>{result?.error?.data?.message}</p>
      </Notification>,
      { placement: "topEnd" }
    );
  } else if (result.meta?.response?.status === 404) {
    //validation error
    toaster.push(
      <Notification
        closable
        type="error"
        header={
          <Translation>
            {(t) => <p>{t("general.error.not_found")}</p>}
          </Translation>
        }
        duration={2000}
      >
        <p>{result?.error?.data?.message}</p>
      </Notification>,
      { placement: "topEnd" }
    );
  } else if (result.meta?.response?.status === 409) {
    //validation error
    toaster.push(
      <Notification
        closable
        type="error"
        header={
          <Translation>
            {(t) => <p>{t("general.error.duplicate")}</p>}
          </Translation>
        }
        duration={2000}
      >
        <p>{result?.error?.data?.message}</p>
      </Notification>,
      { placement: "topEnd" }
    );
  } else if (result.meta?.response?.status === 500) {
    //validation error
    toaster.push(
      <Notification
        closable
        type="error"
        header={
          <Translation>{(t) => <p>{t("general.error.error")}</p>}</Translation>
        }
        duration={2000}
      >
        <p>{result?.error?.data?.description}</p>
      </Notification>,
      { placement: "topEnd" }
    );
  }

  return result;
};

const rawBaseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
});

const dynamicBaseQuery = async (args, api, extraOptions) => {
  const result = await responseHandler(args, api, extraOptions);
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: dynamicBaseQuery,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/auth/signin-student`,
        method: "POST",
        body: data,
        credentials: "include",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLogin(data));
          await dispatch(api.endpoints.currentUser.initiate(null));
        } catch (error) {}
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${apiUrl}/auth/logout`,
        credentials: "include",
        method: "POST",
      }),
    }),
    currentUser: builder.mutation({
      query: () => ({
        url: `${apiUrl}/user/current-user`,
        credentials: "include",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {}
      },
    }),
    refQuery: builder.query({
      query: (data) => ({
        url: `${apiUrl}/${data.endpoint}`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getCountryList: builder.query({
      query: (data) => ({
        url: `${apiUrl}/settings/get-country-list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getCityList: builder.query({
      query: (data) => ({
        url: `${apiUrl}/settings/get-city-list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getProvinceList: builder.query({
      query: (data) => ({
        url: `${apiUrl}/settings/get-province-list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useCurrentUserMutation,
  useGetCountryListQuery,
  useGetCityListQuery,
  useGetProvinceListQuery,

  useRefQueryQuery,
} = api;
