import { Edit } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IconButton } from "rsuite";
import DataTable from "../../components/dataTable";
import Page from "../../components/layout/Page";
import useUser from "../../hooks/useUser";
import { useGetEducationsQuery } from "../../redux/education";
import { getEnumName, initParams } from "../../utils";
import { EducationStatusEnum } from "../../utils/enums";

const MyEducations = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);
  const currentUser = useUser();
  const { data, isLoading, refetch } = useGetEducationsQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const history = useNavigate();
  const edit = (data) => {
    // history(`/educations/${data.id}`);
  };

  const columns = [
    // {
    //   title: t("pages.education.code"),
    //   name: "code",
    //   sortable: true,
    //   align: "left",
    //   type: "text",
    //   flexGrow: 1,
    //   renderCell: (rowData) => (
    //     <span>{rowData.studentEducation_education?.code}</span>
    //   ),
    // },
    {
      title: t("pages.education.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {rowData.studentEducation_education?.code +
            " - " +
            rowData.studentEducation_education?.name}
        </span>
      ),
    },
    {
      title: t("pages.education.language"),
      name: "languageId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 2,
      renderCell: (rowData) => (
        <span>
          {rowData.studentEducation_education?.education_language?.name}
        </span>
      ),
    },
    {
      title: t("pages.education.education_level"),
      name: "educationLevelId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 2,
      renderCell: (rowData) => (
        <span>
          {rowData.studentEducation_education.education_educationLevel?.name}
        </span>
      ),
    },
    {
      title: t("pages.education.term"),
      name: "termId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 2,
      renderCell: (rowData) => (
        <span>{rowData.studentEducation_term?.name}</span>
      ),
    },
    {
      title: t("pages.student.studentEducation.educationStatusId"),
      name: "educationStatusId",
      sortable: true,
      align: "left",
      type: "enum",
      flexGrow: 2,
      renderCell: (rowData) => (
        <>
          {t(
            `general.enums.educationStatus.${getEnumName(
              EducationStatusEnum,
              rowData.educationStatusId
            )}`
          )}
        </>
      ),
    },
    // {
    //   title: t("general.table.actions"),
    //   name: "actions",
    //   width: 100,
    //   renderCell: (rowData) => (
    //     <IconButton
    //       onClick={() => edit(rowData)}
    //       size="xs"
    //       icon={<Edit color="orange" />}
    //     />
    //   ),
    // },
  ];

  return (
    <Page title={t("pages.education.title")}>
      <DataTable
        size="compact"
        wordWrap="break-word"
        columns={columns}
        rowKey="id"
        loading={isLoading}
        data={data?.data}
        params={params}
        rowCount={data?.total}
        setPagination={setParams}
        refetch={refetch}
      />
    </Page>
  );
};

export default MyEducations;
