import React from "react";
import { useTranslation } from "react-i18next";
import Page from "../../components/layout/Page";
import { useGetStudentByIdQuery } from "../../redux/studentApi";
import Profile from "./components/Profile";
import useUser from "../../hooks/useUser";
import Advisor from "./components/Advisor";
import { Col, Grid, Row } from "rsuite";
import MyLessons from "../myLessons";

const Home = () => {
  const { t } = useTranslation();
  const [currentUser] = useUser();
  const { data: student, isLoading: fetchLoading } = useGetStudentByIdQuery(
    {
      id: currentUser?.studentId,
    },
    { skip: !currentUser?.studentId }
  );

  return (
    <Page
      title={`${student?.data?.name || ""} ${student?.data?.surname || ""}`}
    >
      <Grid fluid>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Profile student={student?.data} fetchLoading={fetchLoading} />
          </Col>
          <Col xs={24} sm={12} md={16} lg={16} xl={16}>
            <Row gutter={16}>
              <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                <Advisor />
              </Col>
              <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                <MyLessons disabled />
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </Page>
  );
};

export default Home;
