import { Edit } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Grid, IconButton, Row, Schema } from "rsuite";
import DataTable from "../../components/dataTable";
import Page from "../../components/layout/Page";
import RefDropdown from "../../components/RefDropdown";
import TextInput from "../../components/TextInput";
import { useGetLessonsQuery } from "../../redux/lesson";
import { defaultToolbar, getEnumName, initParams } from "../../utils";
import { EducationStatusEnum } from "../../utils/enums";
const { NumberType } = Schema.Types;

const MyLessons = ({ disabled }) => {
  const { t } = useTranslation();
  const formRef = React.useRef();
  const [params, setParams] = useState(initParams);
  const initialValues = {
    studentEducationId: null,
  };
  const [formValues, setFormValue] = React.useState(initialValues);
  const { data, isLoading, refetch } = useGetLessonsQuery(
    { ...params, studentEducationId: formValues.studentEducationId },
    {
      refetchOnMountOrArgChange: true,
      skip: !formValues.studentEducationId,
    }
  );

  const schema = Schema.Model({
    studentEducationId: NumberType().isRequired(
      t("pages.student.studentGrade.valid.studentEducationId_required")
    ),
  });

  const columns = [
    {
      title: t("pages.lessons.term"),
      name: "termId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>{rowData.studentLesson_lesson.lesson_term?.name}</span>
      ),
    },
    {
      title: t("pages.lessons.name"),
      name: "studentLesson_lesson.name",
      align: "left",
      type: "text",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {rowData.studentLesson_lesson?.code +
            " - " +
            rowData.studentLesson_lesson?.name}
        </span>
      ),
    },
    {
      title: t("pages.lessons.grade"),
      name: "grade",
      sortable: true,
      align: "left",
      flexGrow: 1,
    },
    {
      title: t("pages.lessons.letterGrade"),
      name: "letterGrade",
      align: "left",
      flexGrow: 1,
    },
    // {
    //   title: t("pages.education.language"),
    //   name: "languageId",
    //   sortable: true,
    //   align: "left",
    //   type: "ref",
    //   flexGrow: 2,
    //   renderCell: (rowData) => (
    //     <span>
    //       {rowData.studentEducation_education?.education_language?.name}
    //     </span>
    //   ),
    // },
    // {
    //   title: t("pages.education.education_level"),
    //   name: "educationLevelId",
    //   sortable: true,
    //   align: "left",
    //   type: "ref",
    //   flexGrow: 2,
    //   renderCell: (rowData) => (
    //     <span>
    //       {rowData.studentEducation_education.education_educationLevel?.name}
    //     </span>
    //   ),
    // },
    // {
    //   title: t("pages.student.studentEducation.educationStatusId"),
    //   name: "educationStatusId",
    //   sortable: true,
    //   align: "left",
    //   type: "enum",
    //   flexGrow: 2,
    //   renderCell: (rowData) => (
    //     <>
    //       {t(
    //         `general.enums.educationStatus.${getEnumName(
    //           EducationStatusEnum,
    //           rowData.educationStatusId
    //         )}`
    //       )}
    //     </>
    //   ),
    // },
  ];
  console.log(formValues)
  return (
    <Page title={t("pages.lessons.title")}>
      <Form
        fluid
        model={schema}
        formValue={formValues}
        onChange={setFormValue}
        ref={formRef}
      >
        <Grid fluid>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <TextInput
                name="studentEducationId"
                endpoint="education/student/list"
                labelKey="educationName"
                setDefaultValue={true}
                formValues={formValues}
                setFormValue={setFormValue}
                label={t("pages.student.studentGrade.studentEducationId")}
                accepter={RefDropdown}
              />
            </Col>
          </Row>
        </Grid>
        <DataTable
          size="compact"
          wordWrap="break-word"
          columns={columns}
          rowKey="id"
          loading={isLoading}
          data={data?.data}
          params={params}
          rowCount={data?.total}
          setPagination={setParams}
          toolbar={disabled ? null : defaultToolbar}
          refetch={disabled ? null : refetch}
        />
      </Form>
    </Page>
  );
};

export default MyLessons;
